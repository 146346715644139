<template>
    <div class="ath">
      <div class="switch-wrapper">
        <rnd-btn @click="onScreenShot($refs.screenShot)" :class="[{}, 'absolute']">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M18.2222 4.5H5.77778C4.79594 4.5 4 5.29594 4 6.27778V18.7222C4 19.7041 4.79594 20.5 5.77778 20.5H18.2222C19.2041 20.5 20 19.7041 20 18.7222V6.27778C20 5.29594 19.2041 4.5 18.2222 4.5Z"
                  stroke="currentColor" stroke-width="1.77778" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M20.0001 15.1666L15.5556 10.7222L5.77783 20.4999" stroke="currentColor"
                  stroke-width="1.77778" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.889 10.7223C9.62538 10.7223 10.2223 10.1254 10.2223 9.389C10.2223 8.65262 9.62538 8.05566 8.889 8.05566C8.15262 8.05566 7.55566 8.65262 7.55566 9.389C7.55566 10.1254 8.15262 10.7223 8.889 10.7223Z"
                  stroke="currentColor" stroke-width="1.77778" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </rnd-btn>
      </div>
      <div class="screen-shot-wrapper">
        <section class="content" ref="screenShot">
          <table>
              <thead>
                  <tr>
                      <th class="npp">#</th>
                      <th v-for="header in headers" :key="Object.keys(header)[0]" v-html="header[Object.keys(header)[0]]" />
                  </tr>
              </thead>
              <tbody>
              <template v-for="(group, ndx) in athData.groups">
                  <tr
                          v-for="(row, index) in athData[group.group]"
                          :key="index+group.group"
                  >
                      <td
                              :class="[{'separator': group.group.toString().toLowerCase().includes('main') && index===athData[group.group].length-1}]"
                      >
                          {{index + 1}}
                      </td>
                      <td
                              v-for="header in headers"
                              :key="Object.keys(header)[0]"
                              :class="[
                                  {'blue' : Object.keys(header)[0]==='ticker'},
                                  {'text-green' : Object.keys(header)[0]==='percent' && row[Object.keys(header)[0]].toString()[0] !== '-'},
                                  {'text-red' : Object.keys(header)[0]==='percent' && row[Object.keys(header)[0]].toString()[0] === '-'},
                                  Object.keys(header)[0]==='correction' ? _getBackgroundColor(row[Object.keys(header)[0]]) : '',
                                  {'separator': ndx<(athData.groups.length - 1) && index===athData[group.group].length-1},
                                  {'yellow' : Object.keys(header)[0]==='ticker' && group.group.toString().toLowerCase().includes('main')}
                              ]"
                      >
                          {{Object.keys(header)[0] === "price_current" || Object.keys(header)[0] === "ath_current" ? '$' : ''}}
                          {{
                          row[Object.keys(header)[0]].toString().split("/").length > 1
                          ? row[Object.keys(header)[0]].toString().split("/")[0]
                          : row[Object.keys(header)[0]].toLocaleString()
                          }}
                          {{Object.keys(header)[0] === "percent" || Object.keys(header)[0] === "correction" ? '%' : ''}}
                      </td>
                  </tr>
              </template>
              </tbody>
          </table>
  <!--        middle          -->
          <table class="talbe-middle">
              <thead>
                  <tr>
                      <th class="middle">Ср. по<br/>группе</th>
                  </tr>
              </thead>
              <tbody>
              <template v-for="(group, index) in athData.groups">
                  <tr v-for="item in group.group_items_count" :key="group.group+item+index">
                      <td
                              style="border-right: none"
                              v-if="item===1"
                              :class="[
                                  { 'separator': index!==(athData.groups.length - 1) },
                                  { 'no-border': index===(athData.groups.length - 1) },
                                  group['group_average'].toString().includes('-') ? 'text-red' : 'text-green',
                                  ]"
                              :rowspan="group.group_items_count"
                      >
                          {{group["group_average"]}}%
                      </td>
                      <td style="padding: 5px 0; min-width: 0; max-width: 0; height: 26px; " />
                  </tr>
              </template>
              </tbody>
          </table>
  <!--        colorized table         -->
          <table class="colorized-table">
              <thead>
              <tr>
                  <th/>
                  <th>LOW</th>
                  <th colspan="8">Коррекционное<br/>движение</th>
                  <th>ATH</th>
                  <th>Тикер</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(group, ndx) in athData.groups">
                  <tr v-for="(row, index) in athData[group.group]" :key="`${index}colorisedTable${group.group}`">
                      <td :class="[
                              {'separator': ndx<(athData.groups.length - 1) && index===athData[group.group].length-1}
                              ]"
                      >
                          <span v-if="row.correction_direction==='right'">
                              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6.0958 2.99973L4.79579 1.71199C4.70256 1.61885 4.6286 1.50827 4.57814 1.38657C4.52768 1.26486 4.5017 1.13442 4.5017 1.00269C4.5017 0.870966 4.52768 0.740527 4.57814 0.618826C4.6286 0.497124 4.70256 0.386544 4.79579 0.293397C4.88903 0.200251 4.99972 0.126362 5.12155 0.0759515C5.24337 0.025541 5.37394 -0.000404358 5.5058 -0.000404358C5.63765 -0.000404358 5.76822 0.025541 5.89004 0.0759515C6.01187 0.126362 6.12256 0.200251 6.2158 0.293397L9.2158 3.28945C9.30684 3.38446 9.3782 3.49649 9.4258 3.61912C9.52581 3.86234 9.52581 4.13515 9.4258 4.37837C9.3782 4.501 9.30684 4.61303 9.2158 4.70804L6.2158 7.70409C6.12283 7.79772 6.01223 7.87205 5.89037 7.92277C5.76851 7.97348 5.63781 7.9996 5.5058 7.9996C5.37378 7.9996 5.24308 7.97348 5.12122 7.92277C4.99936 7.87205 4.88876 7.79772 4.79579 7.70409C4.70207 7.61122 4.62767 7.50072 4.5769 7.37899C4.52613 7.25725 4.5 7.12667 4.5 6.99479C4.5 6.86291 4.52613 6.73233 4.5769 6.6106C4.62767 6.48886 4.70207 6.37836 4.79579 6.28549L6.0958 4.99776H1.5C1.23478 4.99776 0.980429 4.8925 0.792892 4.70515C0.605356 4.5178 0.5 4.2637 0.5 3.99874C0.5 3.73379 0.605356 3.47969 0.792892 3.29233C0.980429 3.10498 1.23478 2.99973 1.5 2.99973H6.0958Z" fill="#13CB89"/>
                              </svg>
                          </span>
                          <span v-else-if="row.correction_direction==='left'">
                              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M3.9042 2.99973L5.20421 1.71199C5.29744 1.61885 5.3714 1.50827 5.42186 1.38657C5.47232 1.26486 5.4983 1.13442 5.4983 1.00269C5.4983 0.870966 5.47232 0.740527 5.42186 0.618826C5.3714 0.497124 5.29744 0.386544 5.20421 0.293397C5.11097 0.200251 5.00028 0.126362 4.87845 0.0759515C4.75663 0.025541 4.62606 -0.000404358 4.49421 -0.000404358C4.36235 -0.000404358 4.23178 0.025541 4.10996 0.0759515C3.98813 0.126362 3.87744 0.200251 3.78421 0.293397L0.784204 3.28945C0.693164 3.38446 0.621799 3.49649 0.574204 3.61912C0.474186 3.86234 0.474186 4.13515 0.574204 4.37837C0.621799 4.501 0.693164 4.61303 0.784204 4.70804L3.78421 7.70409C3.87717 7.79772 3.98777 7.87205 4.10963 7.92277C4.23149 7.97348 4.36219 7.9996 4.49421 7.9996C4.62622 7.9996 4.75692 7.97348 4.87878 7.92277C5.00064 7.87205 5.11124 7.79772 5.20421 7.70409C5.29793 7.61122 5.37233 7.50072 5.4231 7.37899C5.47387 7.25725 5.5 7.12667 5.5 6.99479C5.5 6.86291 5.47387 6.73233 5.4231 6.6106C5.37233 6.48886 5.29793 6.37836 5.20421 6.28549L3.9042 4.99776H8.5C8.76522 4.99776 9.01957 4.8925 9.20711 4.70515C9.39464 4.5178 9.5 4.2637 9.5 3.99874C9.5 3.73379 9.39464 3.47969 9.20711 3.29233C9.01957 3.10498 8.76522 2.99973 8.5 2.99973H3.9042Z" fill="#E4291E"/>
                              </svg>
                          </span>
                          <span v-else>
                              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M8.59 0L5 3.59L1.41 0L0 1.41L3.59 5L0 8.59L1.41 10L5 6.41L8.59 10L10 8.59L6.41 5L10 1.41L8.59 0Z" fill="white"/>
                              </svg>
                          </span>
                      </td>
                      <td
                              v-for="col in 10"
                              :key="col"
                              :class="[
                                  'colorized-table',
                                  backColor[col-1],
                                  {'separator': ndx<(athData.groups.length - 1) && index===athData[group.group].length-1}
                                  ]"
                      >
                          {{ col===1
                          ? -90 > row.correction ? `${row.correction.toLocaleString()} %` : ''
                          : col===2
                          ? (-80 > row.correction && row.correction > -90) ? `${row.correction.toLocaleString()} %` : ''
                          : col===3
                          ? (-70 > row.correction && row.correction > -80) ? `${row.correction.toLocaleString()} %` : ''
                          : col===4
                          ? (-60 > row.correction && row.correction > -70) ? `${row.correction.toLocaleString()} %` : ''
                          : col===5
                          ? (-50 > row.correction && row.correction > -60) ? `${row.correction.toLocaleString()} %` : ''
                          : col===6
                          ? (-40 > row.correction && row.correction > -50) ? `${row.correction.toLocaleString()} %` : ''
                          : col===7
                          ? (-30 > row.correction && row.correction > -40) ? `${row.correction.toLocaleString()} %` : ''
                          : col===8
                          ? (-20 > row.correction && row.correction > -30) ? `${row.correction.toLocaleString()} %` : ''
                          : col===9
                          ? (-10 > row.correction && row.correction > -20) ? `${row.correction.toLocaleString()} %` : ''
                          : col===10
                          ? (0 > row.correction && row.correction > -10) ? `${row.correction.toLocaleString()} %` : ''
                          : ''
                          }}
                      </td>
                      <td :class="['blue', {'separator': ndx<(athData.groups.length - 1) && index===athData[group.group].length-1}, {'yellow' : group.group.toString().toLowerCase().includes('main')}]">
                          {{row.ticker.toString().split("/")[0]}}
                      </td>
                  </tr>
              </template>
              </tbody>
              <thead>
              <tr class="no-bottom">
                  <th />
                  <th
                          v-for="h in clorizedHeaders"
                          :key="h"
                          :colspan="h===-50 ? 2 : ''"
                          :class="[
                                  {'text-red' : h===-90},
                                  {'text-light-red' : h===-80},
                                  {'text-orange' : h===-70},
                                  {'text-light-orange' : h===-60},
                                  {'text-yellow' : h===-50},
                                  {'text-light-salat' : h===-40},
                                  {'text-salat' : h===-30},
                                  {'text-light-green' : h===-20},
                                  {'text-green' : h===-10},
                              ]"
                  >
                      {{h}}%
                  </th>
                  <th></th>
              </tr>
              </thead>
          </table>
        </section>
      </div>
    </div>
</template>

<script>
    import RndBtn from "@/components/Blocks/RndBtn/RndBtn";
    import domtoimage from "dom-to-image-more";
    export default {
        name: "AthPage",
        components: {RndBtn},
        props: {
            headers: Array,
            athData: [Object, null]
        },
        computed: {
            _main() {
                return this.athData ? this.athData.main : []
            },
            _other() {
                return this.athData ? this.athData.other : []
            },
            mainAverage() {
                return this.athData ? this.athData.groups.find(el => el.group === "main")?.["group_average"] : 0
            },
            otherAverage() {
                return this.athData ? this.athData.groups.find(el => el.group === "other")?.["group_average"] : 0
            },
            mainQuantity() {
                return this.athData ? this.athData.groups.find(el => el.group === "main")?.["group_items_count"] : 0
            },
            clorizedHeaders() {
                return [
                    -90, -80, -70, -60, -50, -40, -30, -20, -10
                ]
            },
            backColor() {
                return [
                    "red", "light-red", "orange", "light-orange", "yellow", "yellow", "light-salat", "salat", "light-green", "green"
                ]
            }
        },
        methods: {
            _getBackgroundColor(val) {
                return -90 > val ? 'text-red'
                    :(-80 > val && val > -90) ? ''
                        :(-70 > val && val > -80) ? ''
                            :(-60 > val && val > -70) ? ''
                                :(-40 > val && val > -60) ? 'text-yellow'
                                    :(-30 > val && val > -50) ? ''
                                        :(-20 > val && val > -40) ? ''
                                            :(-10 > val && val > -30) ? ''
                                                :(0 > val && val > -20) ? 'text-green'
                                                    : ''
            },
          async onScreenShot(screenShotArea) {
            const height = screenShotArea.offsetHeight;
            const width = screenShotArea.offsetWidth;
            await domtoimage.toJpeg(screenShotArea, {quality: 0.9, height: height, width: width, style: {'overflow': 'hidden'}})
                .then((dataUrl) => {
                  this.doingScreenshot = false
                  const link = document.createElement('a');
                  link.download = 'screenshot.jpeg';
                  link.href = dataUrl;
                  link.click();
                })
          },
        }
    }
</script>

<style scoped lang="scss">
    .ath {
      margin-top: 100px;
      overflow: hidden;
      max-height: calc(100% - 118px);
      display: flex;
      flex-direction: column;
      gap: 20px;
      & .screen-shot-wrapper {
        display: flex;
        column-gap: 20px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
        & .content {
          display: flex;
          column-gap: 20px;
          height: max-content;
          & table {
            border: 2px solid #5b5c67;
            box-sizing: border-box;
            border-radius: 15px;
            overflow: hidden;
            border-spacing: 0;

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: .0125em;

            flex: 1 1 auto;
            & tr {
              &.no-bottom {
                & th {
                  border-bottom: none;
                }
              }
              & th, & td {
                max-width: 85px;
                padding: 10px 5px;
                &.text- {
                  &red {
                    color: $red-middle;
                  }
                  &light-red {
                    color: #FF7575;
                  }

                  &orange {
                    color: #FF8F8F;
                  }

                  &light-orange {
                    color: #F8CBAD;
                  }
                  &yellow {
                    color: #FFD966;
                  }
                  &light-salat {
                    color: #CBE9C1;
                  }
                  &salat {
                    color: #A9D18E;
                  }
                  &light-green {
                    color: #92D050;
                  }
                  &green {
                    color: $green;
                  }
                }
              }
              & td {
                padding: 5px 5px;
                &.colorized-table {
                  min-width: 90px;
                }
              }
              & th, & td {
                text-align: center;
                box-sizing: border-box;
                border-bottom: 2px solid #5b5c67;
                border-right: 2px solid #5b5c67;
                transition: all .3s;
                position: relative;
                z-index: 1;
                height: 26px;
                min-width: 80px;
                &:before {
                  content: '';
                  opacity: .3;
                  position: absolute;
                  left: 0; right: 0;
                  top: 0; bottom: 0;
                  z-index: -1;
                }
                &:last-child {
                  border-right: none;
                }
                &.npp {
                  width: 30px;
                }
                &.middle {
                  max-width: 60px;
                }

                &.blue {
                  &:before {
                    background-color: $blue-middle;
                  }
                }
                &.green {
                  &:before {
                    background-color: $green;
                  }
                }
                &.red {
                  &:before {
                    background-color: $red-middle;
                  }
                }
                &.light-red {
                  &:before {
                    background-color: #FF7575;
                  }
                }
                &.orange {
                  &:before {
                    background-color: #FF8F8F;
                  }
                }
                /*", "light-green"*/
                &.light-orange {
                  &:before {
                    background-color: #F8CBAD;
                  }
                }
                &.yellow {
                  &:before {
                    background-color: #FFD966;
                  }
                }
                &.light-salat {
                  &:before {
                    background-color: #CBE9C1;
                  }
                }
                &.salat {
                  &:before {
                    background-color: #A9D18E;
                  }
                }
                &.light-green {
                  &:before {
                    background-color: #92D050;
                  }
                }
                &.green {
                  &:before {
                    background-color: $green;
                  }
                }
                &.separator {
                  border-bottom: 7px solid #5b5c67;
                }
                &.no-border {
                  border-bottom: none;
                }
              }
              &:last-child {
                td {
                  border-bottom: none;
                }
              }
              &:hover {
                & td {
                  background-color: #41424b;
                }
              }
            }
            &.talbe-middle {
              max-width: 80px;
              flex: 0 0 80px;
            }
            &.colorized-table {
              & tr:last-child {
                & td {
                  border-bottom: 2px solid #5b5c67;
                }
              }
            }
            & thead {
              & tr {
                & th {
                  &:before {
                    content: '';
                    position: absolute;
                    left: 0; right: 0;
                    top: 0; bottom: 0;
                    background-color: #FFFFFF4D;
                  }
                }
              }
            }
            &:not(.colorized-table) {
              margin-bottom: 34px;
            }
          }
        }
      }
    }
</style>
