import { render, staticRenderFns } from "./CicleLoader.vue?vue&type=template&id=c89df870&scoped=true"
import script from "./CicleLoader.vue?vue&type=script&lang=js"
export * from "./CicleLoader.vue?vue&type=script&lang=js"
import style0 from "./CicleLoader.vue?vue&type=style&index=0&id=c89df870&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c89df870",
  null
  
)

export default component.exports