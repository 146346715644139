<template>
    <div v-bind="$attrs" :class="['rnd-btn', {'pressed': pressed}]" @click="emitting" @mousedown="pressDown" @mouseup="pressUp">
        <cicle-loader v-if="load"/>
        <slot v-else/>
    </div>
</template>

<script>
    import CicleLoader from "../CicleLoader/CicleLoader";
    export default {
        name: "RndBtn",
        components: {CicleLoader},
        props: ["load"],
        data: () => ({
           pressed: false
        }),
        methods: {
            emitting(event) {
                if (!this.load && !this.$attrs.disabled) {
                    this.$emit("click", event)
                }
            },
            pressDown(event) {
                this.pressed=true
                if (!this.load) {
                    this.$emit("mousedown", event)
                }
            },
            pressUp(event) {
                this.pressed=false
                if (!this.load) {
                    this.$emit("mouseup", event)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .rnd-btn {
        position: relative;
        z-index: 1;
        min-width: 44px;
        min-height: 44px;
        max-width: 44px;
        max-height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 300ms ease-in-out;
        cursor: pointer;
        color: $white;

        &.pressed {
            & svg {
                transform: scale(0.95);
            }
        }

        &:after {
            transition: all 0.2s linear;
            content: '';
            height: 100%;
            width: 100%;
            transform: scale(0);
            border-radius: 50%;
            position: absolute;
            z-index: -1;
            background-color: $grayscale-middle-light;
        }

        &:not([disabled]):hover:after {
            transform: scale(1);
        }
        &:hover {
          color: white;
        }
        &[disabled] {
          color: white;
            & svg {
                opacity: 0.4;
            }
        }
    }
</style>
