<template>
    <transition name="fade" mode="in-out">
        <ath-page :headers="headers" :ath-data="GET_ATH" />
    </transition>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import AthPage from "../components/Pages/AthPage";

    export default {
        name: 'ATH',
        components: {
            AthPage
        },
        data() {
            return {
                headers: [
                    {ticker: "Тикер"},
                    {price_current: "Цена сейчас"},
                    {percent: "Изм. за 24 часа"},
                    {market: "Биржа"},
                    {ath_current: "Текущий АТН"},
                    {correction: "Коррекция<br />от АТН(%)"}
                ],
            }
        },
        computed: {
            ...mapGetters(["GET_ATH"])
        },
        methods: {
            ...mapActions(["SET_LOADER", "getATH", "setREFRESH", "clearREFRESH"]),

            async refreshSignal() {
                this.SET_LOADER(true)
                await this.getATH()
                this.setREFRESH(this.refreshSignal)
                this.SET_LOADER(false)
            },
        },
        async mounted() {
            await this.refreshSignal()
        },
        destroyed() {
            this.clearREFRESH()
        },
    }
</script>
<style lang="scss">

</style>
