<template>
    <svg class="loader" viewBox="0 0 24 24">
        <circle class="loader__value" cx="12" cy="12" r="10" />
        <circle class="loader__value" cx="12" cy="12" r="10" />
        <circle class="loader__value" cx="12" cy="12" r="10" />
        <circle class="loader__value" cx="12" cy="12" r="10" />
        <circle class="loader__value" cx="12" cy="12" r="10" />
        <circle class="loader__value" cx="12" cy="12" r="10" />
    </svg>
</template>

<script>
    export default {
        name: "CicleLoader"
    }
</script>

<style scoped lang="scss">
    $loader-colors: $yellow-middle;
    $loader-dash: 63;
    $loader-duration: length($loader-colors) * 1s;
    $loader-duration-alt: $loader-duration / length($loader-colors);
    $loader-keyframe: 1 / (length($loader-colors) * 2) * 100;

    .loader {
        animation: loader-turn $loader-duration-alt linear infinite;
        max-width: 24px;
        width: 100%;

        @keyframes loader-turn {
            50% { transform: rotate(180deg) }
            100% { transform: rotate(720deg) }
        }
    }

    .loader__value {
        animation: loader-stroke $loader-duration linear infinite;
        fill: none;
        stroke-dasharray: $loader-dash;
        stroke-dashoffset: $loader-dash;
        stroke-linecap: round;
        stroke-width: 2;

        @for $i from 1 through length($loader-colors) {
            &:nth-child(#{$i}) {
                stroke: nth($loader-colors, $i);

                @if $i > 1 {
                    animation-delay: ($i - 1) * $loader-duration-alt;
                }
            }
        }

        @keyframes loader-stroke {
            #{$loader-keyframe * 1%} { stroke-dashoffset: 0 }
            #{$loader-keyframe * 2%}, 100% { stroke-dashoffset: $loader-dash }
        }
    }
</style>